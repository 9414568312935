import React, { useState } from 'react';
import styled from 'styled-components';

const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
  position: relative;
`;

const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #333;
  cursor: pointer;
  padding: 8px 14px;
  background-color: ${({ isSelected }) => (isSelected ? '#cce7ff' : '#f7f7f7')}; /* Active filter background */
  border-radius: 6px;
  margin-bottom: 6px;
  transition: background-color 0.3s ease, transform 0.3s ease;
  justify-content: space-between; /* Ensures the button is at the end */
`;

const CollapseButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  padding: 0;
  color: #333;
  font-size: 18px;
  cursor: pointer;
  transition: transform 0.3s ease;

  &:focus {
    outline: none;
  }

  &:hover {
    transform: rotate(45deg);
  }
`;

const DropdownContainer = styled.div`
  padding-left: 16px;
  margin-top: 6px;
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
`;

const DropdownOption = styled.label`
  display: flex;
  align-items: center;
  padding: 6px 14px;
  font-size: 14px;
  color: #333;
  cursor: pointer;
  margin-bottom: 8px;
  border-radius: 4px;
  transition: background-color 0.3s ease;

  &:hover {
    color: #0066cc;
    background-color: #f0f0f0;
  }
`;

const CheckboxInput = styled.input`
  margin-right: 12px;
  cursor: pointer;
`;

const SelectedValuesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 6px;
`;

const SelectedValue = styled.div`
  margin-right: 6px;
  margin-bottom: 6px;
  padding: 4px 8px;
  border-radius: 20px;
  background-color: ${({ isSelected }) => (isSelected ? '#0066cc' : '#f0f0f0')}; /* Selected value background color */
  font-size: 14px;
  color: ${({ isSelected }) => (isSelected ? '#fff' : '#333')}; /* Text color for selected value */
  cursor: pointer;

  &:hover {
    background-color: #ff4d4d;
    color: #fff;
  }
`;

const SelectedValues = ({ selectedValues, handleRemove }) => {
  return (
    <SelectedValuesContainer>
      {selectedValues.map((value) => (
        <SelectedValue key={value} isSelected onClick={() => handleRemove(value)}>
          {value} ×
        </SelectedValue>
      ))}
    </SelectedValuesContainer>
  );
};

function CheckboxList({ values, onChange, title }) {
  const [checkedItems, setCheckedItems] = useState({});
  const [isExpanded, setIsExpanded] = useState(false);

  const handleChange = (event) => {
    const { name, checked } = event.target;
    const newCheckedItems = { ...checkedItems, [name]: checked };
    setCheckedItems(newCheckedItems);

    const checkedValues = Object.keys(newCheckedItems).filter((key) => newCheckedItems[key]);
    onChange(checkedValues);
  };

  const toggleCollapse = () => {
    setIsExpanded(!isExpanded);
  };

  const handleRemove = (value) => {
    // Remove the value from checkedItems
    const newCheckedItems = { ...checkedItems };
    delete newCheckedItems[value];

    setCheckedItems(newCheckedItems);
    const checkedValues = Object.keys(newCheckedItems).filter((key) => newCheckedItems[key]);
    onChange(checkedValues);
  };

  const selectedValues = Object.keys(checkedItems).filter((key) => checkedItems[key]);

  return (
    <CheckboxContainer>
      {/* Entire label is now clickable to toggle collapse */}
      <CheckboxLabel onClick={toggleCollapse} isSelected={selectedValues.length > 0}>
        <h1 style={{ marginBottom: '1px', fontWeight: 'normal', fontSize: '16px' }}>{title}</h1>
        <CollapseButton>
          {isExpanded ? '−' : '+'}
        </CollapseButton>
      </CheckboxLabel>

      {selectedValues.length > 0 && (
        <SelectedValues selectedValues={selectedValues} handleRemove={handleRemove} />
      )}

      <DropdownContainer isOpen={isExpanded}>
        {values.map((value) => (
          <DropdownOption key={value}>
            <CheckboxInput
              type="checkbox"
              name={value}
              checked={checkedItems[value]}
              onChange={handleChange}
            />
            {value}
          </DropdownOption>
        ))}
      </DropdownContainer>
    </CheckboxContainer>
  );
}

export default CheckboxList;
