import React from 'react';
import './HowItWorks.css';
import { FaFilter, FaShoppingCart, FaBell, FaCheckCircle } from 'react-icons/fa';
import { useHistory } from 'react-router-dom'; // Import useHistory

const steps = [
  {
    icon: <FaFilter />,
    title: "Set Your Preferences",
    description: "Pick your favorite brands, sizes, and categories. We tailor results for you!"
  },
  {
    icon: <FaShoppingCart />,
    title: "Browse Effortlessly",
    description: "We filter out the noise and show you the best deals instantly."
  },
  {
    icon: <FaBell />,
    title: "Get Alerts (Coming Soon!)",
    description: "Receive notifications when new deals or price drops match your preferences."
  },
  {
    icon: <FaCheckCircle />,
    title: "Pick & Purchase",
    description: "No need to search endlessly—we do the work, you just choose!"
  }
];

function HowItWorks() {
  const history = useHistory(); // Initialize history

  const handleButtonClick = () => {
    history.push('/services'); // Navigate to /services when button is clicked
  };

  return (
    <div className="how-it-works-container">
      {/* Hero Section */}
      <div className="hero-section">
        <h1>We Find the Best Deals, You Just Pick!</h1>
        <p>Set your preferences, browse easily, and let us do the searching for you.</p>
      </div>

      {/* Steps Section */}
      <div className="steps-container">
        {steps.map((step, index) => (
          <div key={index} className="step-card">
            <div className="icon">{step.icon}</div>
            <h2>{step.title}</h2>
            <p>{step.description}</p>
          </div>
        ))}
      </div>

      {/* CTA */}
      <div className="cta-section">
        <button className="cta-button" onClick={handleButtonClick}>Start Browsing Now</button>
      </div>
    </div>
  );
}

export default HowItWorks;
