import React, { useState } from 'react';
import { Link } from 'react-router-dom'; // Add Link import
import './SupportModal.css'; // Import custom CSS for styling

function SupportModal() {
  const [isOpen, setIsOpen] = useState(false); // Modal visibility state
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState(''); // New state for email
  const [query, setQuery] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const toggleModal = () => {
    setIsOpen(!isOpen); // Toggle the modal visibility
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setErrorMessage('');
    setSuccessMessage('');

    // API endpoint URL (replace this with your actual API URL)
    const apiUrl = 'https://price-perfect.onrender.com/v1/user-request/submit-support';

    const requestData = {
      fullName: fullName,
      email: email, // Add email to the request
      query: query,
    };

    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestData),
      });

      if (response.ok) {
        setSuccessMessage('Your support request has been submitted successfully.');
        setFullName('');
        setEmail('');
        setQuery('');
      } else {
        setErrorMessage('There was an error submitting your request. Please try again.');
      }
    } catch (error) {
      setErrorMessage('There was an error submitting your request. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      {/* Trigger the modal on clicking the 'Support' link */}
      <Link onClick={toggleModal} className="support-link">
        Support
      </Link>

      {/* Modal - Only visible if `isOpen` is true */}
      {isOpen && (
        <div className="support-modal-overlay">
          <div className="support-modal">
            <h2>Support Form</h2>

            {errorMessage && <p className="error-message">{errorMessage}</p>}
            {successMessage && <p className="success-message">{successMessage}</p>}

            <form onSubmit={handleSubmit}>
              <div className="input-group">
                <label htmlFor="fullName">Full Name</label>
                <input
                  type="text"
                  id="fullName"
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                  placeholder="Enter your full name"
                  required
                />
              </div>

              <div className="input-group">
                <label htmlFor="email">
                  Email 
                  <span className="optional">(Optional)</span>
                </label>
                <input
                  type="email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Enter your email"
                />
              </div>

              <div className="input-group">
                <label htmlFor="query">Your Query</label>
                <textarea
                  id="query"
                  value={query}
                  onChange={(e) => setQuery(e.target.value)}
                  placeholder="Describe your query"
                  required
                />
              </div>

              <div className="button-group">
                <button type="submit" disabled={isSubmitting}>
                  {isSubmitting ? 'Submitting...' : 'Submit'}
                </button>
                <button type="button" onClick={toggleModal} className="close-btn">
                  Close
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
}

export default SupportModal;
