import React, { useState } from "react";
import "./Footer.css";
import { Button } from "./Button";
import { Link } from "react-router-dom";
import SupportModal from "./pages/SupportModal";

function Footer() {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [toast, setToast] = useState(null);

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubscribe = async () => {
    if (!email.trim() || !isValidEmail(email)) {
      showToast("Please enter a valid email!", "error");
      return;
    }

    setLoading(true);

    try {
      const response = await fetch("https://price-perfect.onrender.com/v1/user-request/subscribe", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
      });
      const responseData = await response.json();

      if (response.ok) {
        showToast("🎉 Successfully Subscribed!", "success");
        setEmail("");
      } else {
        throw new Error(responseData.message);
      }
    } catch (error) {
      showToast(error.message, "error");
    } finally {
      setLoading(false);
    }
  };

  const showToast = (message, type) => {
    setToast({ message, type });
    setTimeout(() => setToast(null), 3000);
  };

  return (
    <div className="footer-container">
      {toast && (
        <div className="custom-toast-container">
          <div className={`custom-toast ${toast.type}`}>{toast.message}</div>
        </div>
      )}

      <section className="footer-subscription">
        <p className="footer-subscription-heading">
          Join the Adventure newsletter to receive our best shopping deals
        </p>
        <p className="footer-subscription-text">You can unsubscribe at any time.</p>
        <div className="input-areas">
          <form onSubmit={(e) => e.preventDefault()}>
            <input
              className="footer-input"
              name="email"
              type="email"
              placeholder="Your Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              disabled={loading}
            />
            <Button buttonStyle="btn--outline" onClick={handleSubscribe} disabled={loading}>
              {loading ? "Subscribing..." : "Subscribe"}
            </Button>
          </form>
        </div>
      </section>

      <div className="footer-links">
        <div className="footer-link-wrapper">
          <div className="footer-link-items">
            <h2>About Us</h2>
            <Link to="/how-it-works">How it works</Link>
            <Link to="/">Careers</Link>
            {/* <Link to="/">Investors</Link>
            <Link to="/">Terms of Service</Link> */}
          </div>
          <div className="footer-link-items">
            <h2>Contact Us</h2>
            <Link to="/contact">Contact</Link> 
            {/* <Link to="/">Support</Link> */}
            <SupportModal />
          </div>
        </div>
        <div className="footer-link-wrapper">
          <div className="footer-link-items">
            <h2>Social Media</h2>
            <Link to="/">Instagram</Link>
            <Link to="/">Facebook</Link>
            <Link to="/">Youtube</Link>
            <Link to="/">Twitter</Link>
          </div>
        </div>
      </div>

      <section className="social-media">
        <div className="social-media-wrap">
          <div className="footer-logo">
            <Link to="/" className="navbar-logo">
              PRICE PERFECT <i className="fa-brands fa-pied-piper-pp" />
            </Link>
          </div>
          <small className="website-rights">PP © 2022</small>
          <div className="social-icons">
            <Link className="social-icon-link facebook" to="/" target="_blank" aria-label="Facebook">
              <i className="fab fa-facebook-f" />
            </Link>
            <Link className="social-icon-link instagram" to="/" target="_blank" aria-label="Instagram">
              <i className="fab fa-instagram" />
            </Link>
            <Link className="social-icon-link youtube" to="/" target="_blank" aria-label="Youtube">
              <i className="fab fa-youtube" />
            </Link>
            <Link className="social-icon-link twitter" to="/" target="_blank" aria-label="Twitter">
              <i className="fab fa-twitter" />
            </Link>
            <Link className="social-icon-link linkedin" to="/" target="_blank" aria-label="LinkedIn">
              <i className="fab fa-linkedin" />
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;
