const searchProducts = async (data, page = 1) => {
  try {
    const response = await fetch("https://price-perfect.onrender.com/v2/search", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...data, page }), // Ensure page is included
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const products = await response.json();
    return products;
  } catch (error) {
    console.error("Failed to fetch products:", error);
    throw error;
  }
};

export default searchProducts;
