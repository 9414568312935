import React, { useState, useEffect, useRef, useCallback } from "react";
import CheckboxList from "./CheckboxList";
import { Products } from "./Card";
import "./Search.css";
import searchProducts from "../../services/searchService";
import Loader from "../Loader";  // Import the new loader component

function Search() {
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [selectedSizes, setSelectedSizes] = useState([]);
  const [selectedGenders, setSelectedGenders] = useState([]);
  const [selectedColors, setSelectedColors] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const observer = useRef(null);
  
  const brands = ["Abercrombie & Fitch", "Armani Exchange", "Athleta", "Banana Republic", "Calvin Klein", "Columbia", "DKNY", "Gap", "Guess", "Hollister", "Hugo Boss", "Lacoste", "Levi's", "Old Navy", "Polo Ralph Lauren", "Tommy Hilfiger", "Others"];
  const sizes = ["XXS", "XS", "S", "M", "L", "XL", "XXL"];
  const genders = ["MENS", "WOMENS"];
  const colors = ["Red", "Orange", "Yellow", "Green", "Gold", "Grey", "Pink", "Gray", "Beige", "Blue", "Purple", "White", "Brown", "Black", "Multi"];
  const categories = ["T-shirts", "Tops", "Shirts", "Jeans", "Polos", "Accessories", "Blazers", "Hoodies", "Jackets & Coats", "Joggers", "Pants", "Shorts", "Sweaters", "Sweatshirt", "Swimwear", "Others"];

  const handleCheckboxChange = (checkedValues, setType) => {
    setType(checkedValues);
  };

  const fetchProducts = useCallback(async (reset = false) => {
    if (isLoading || !hasMore) return;
    setIsLoading(true);

    const currentPage = reset ? 1 : page;

    const data = {
      brands: selectedBrands,
      sizes: selectedSizes,
      genders: selectedGenders,
      colors: selectedColors,
      categories: selectedCategories,
    };

    try {
      const newProducts = await searchProducts(data, currentPage);

      setProducts((prevProducts) => (reset ? newProducts : [...prevProducts, ...newProducts]));

      if (newProducts.length < 100) {
        setHasMore(false);
      } else {
        setPage((prevPage) => prevPage + 1);
      }
    } catch (error) {
      console.error("Failed to search products:", error);
    } finally {
      setIsLoading(false);
    }
  }, [isLoading, hasMore, page, selectedBrands, selectedSizes, selectedGenders, selectedColors, selectedCategories]);

  const handleSubmit = () => {
    setProducts([]);
    setPage(1);
    setHasMore(true);
    fetchProducts(true);
  };

  // Intersection Observer for Infinite Scroll
  useEffect(() => {
    if (!hasMore) return;

    const observerInstance = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && !isLoading) {
          fetchProducts();
        }
      },
      { threshold: 1.0 }
    );

    if (observer.current) observerInstance.observe(observer.current);

    return () => {
      if (observer.current) observerInstance.unobserve(observer.current);
    };
  }, [fetchProducts, hasMore, isLoading]);

  return (
    <div className="search-container">
      <div className="filter-sidebar">
        <h2>FILTERS</h2>
        <CheckboxList values={brands} title="Brand" onChange={(checkedValues) => handleCheckboxChange(checkedValues, setSelectedBrands)} />
        <CheckboxList values={sizes} title="Size" onChange={(checkedValues) => handleCheckboxChange(checkedValues, setSelectedSizes)} />
        <CheckboxList values={genders} title="Gender" onChange={(checkedValues) => handleCheckboxChange(checkedValues, setSelectedGenders)} />
        <CheckboxList values={colors} title="Color" onChange={(checkedValues) => handleCheckboxChange(checkedValues, setSelectedColors)} />
        <CheckboxList values={categories} title="Category" onChange={(checkedValues) => handleCheckboxChange(checkedValues, setSelectedCategories)} />
        <button className="apply-button" onClick={handleSubmit} disabled={isLoading}>
          {isLoading ? <Loader /> : "Apply"}
        </button>
      </div>

      <div className="product-display">
        <Products products={products} />
        <div ref={observer} className="load-more">
          {isLoading && <Loader />}
        </div>
      </div>
    </div>
  );
}

export default Search;
