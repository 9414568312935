import React from 'react';
import './Contact.css'; // Import the new CSS for styling
import { FaEnvelope, FaPhone } from 'react-icons/fa';

function Contact() {
  return (
    <div className="contact-container">
      {/* Hero Section */}
      <div className="hero-section">
        <h1>Contact Us</h1>
        <p>We’re here to help you find the best deals at the best prices!</p>
      </div>

      {/* About Us Section */}
      <div className="about-section">
        <h2>About Us</h2>
        <p>We’re a team of passionate engineers working to make shopping easier and cheaper for everyone. Our goal is simple—help you browse the best available products and find deals that save you time and money.</p>
      </div>

      {/* Contact Info Section */}
      <div className="contact-info-section">
        <h2>Contact Information</h2>
        <div className="contact-details">
          <div>
            <FaEnvelope size={30} />
            <span>Email: <a href="mailto:karthik@price-perfect.com">karthik@price-perfect.com</a></span>
          </div>
          <div>
            <FaPhone size={30} />
            <span>Phone: (408) 471-8871</span>
          </div>
        </div>
      </div>

      {/* Shopping Images Section */}
      <div className="images-section">
        <img src="https://images.unsplash.com/photo-1504198458649-3128b932f49e?q=80&w=2840&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="Shopping Image 1" />
        <img src="https://plus.unsplash.com/premium_photo-1700056213763-3174eb00f970?q=80&w=3087&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="Shopping Image 2" />
      </div>
    </div>
  );
}

export default Contact;
