import React from 'react';
import '../../App.css';
import HeroSection from '../HeroSection';
import Footer from '../Footer';
import { useEffect } from 'react';

function Home() {
  useEffect(() => {
    const removeIframe = setInterval(() => {
      document.querySelectorAll('iframe[style*="position: fixed"]').forEach(el => el.remove());
    }, 1000);

    return () => clearInterval(removeIframe); // Cleanup on unmount
  }, []);
  return (
    <>
      <HeroSection />
      <Footer />
    </>
  );
}

export default Home;
